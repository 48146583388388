<template>
    <el-card>
        <el-row :gutter="8">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-col :span="5">
                    <el-form-item label="主机记录" prop="name">
                        <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item label="域名选择" prop="rename">
                        <el-select v-model="ruleForm.rename" placeholder="请选择域名">
                            <el-option label="frp.one" value="frp.one"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item label="记录类型" prop="type">
                        <el-select v-model="ruleForm.type" placeholder="请选择记录类型">
                            <el-option label="A" value="A"></el-option>
                            <el-option label="SRV" value="SRV"></el-option>
                            <el-option label="CNAME" value="CNAME"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item label="记录隧道" prop="tunnel">
                        <el-select v-model="ruleForm.tunnel" placeholder="请选择记录隧道">
                            <el-option label="隧道ID:" value=""></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
                    </el-form-item>
                </el-col>
            </el-form>
        </el-row>
    </el-card>
</template>
<script>
export default {
    data() {
        return {
            ruleForm: {
                name: '',
                rename: '',
                type: '',
                tunnel: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入主机记录', trigger: 'blur' }
                ],
                rename: [
                    { required: true, message: '请选择域名', trigger: 'change' }
                ],
                type: [
                    { required: true, message: '请选择主机记录', trigger: 'change' }
                ],
                tunnel: [
                    { required: true, message: '请选择记录隧道', trigger: 'change' }
                ],
            }
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert('此功能正在开发中');
                } else {
                    console.log('此功能正在开发中');
                    return false;
                }
            });
        },
    }
}
</script>